import GenericApi from 'root-resources/generic-api';
import Cliente from 'root-models/cliente';

/**
 * API do Cliente
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @extends {RootApi}
 */
class ClienteAPI extends GenericApi<Cliente> {
  /**
   * Injeta a instância do axios com o endpoint base referente ao Usuário
   */
  public constructor() {
    super({ baseEndpoint: '/clientes' });
  }
}

export default ClienteAPI;
