import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useStyles } from '../app.styles';
import { FormikErrors, FormikTouched } from 'formik';
import { EditarGrupoEconomicoFormikValuesType } from './editar-grupo-economico';
import { AutoComplete, TextField } from '@bubotech/sumora-react-components/lib';
import Cliente from 'root-models/cliente';
import ClienteAPI from 'root-resources/api/cliente';

export type EditarDadosUsuarioPropType = {
  onChange: (e: React.ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
  values: EditarGrupoEconomicoFormikValuesType;
  errors: FormikErrors<EditarGrupoEconomicoFormikValuesType>;
  touched: FormikTouched<EditarGrupoEconomicoFormikValuesType>;
};

/**
 * View de edição de dados de um grupo economico
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @param {EditarDadosUsuarioPropType} props
 */
function EditarDadosUsuario(props: EditarDadosUsuarioPropType): JSX.Element {
  const classes = useStyles();
  const { setFieldValue, handleBlur, values, errors, touched } = props;
  const clienteAPI = new ClienteAPI();
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(Math.random());
  }, [values.cliente]);

  return (
    <Grid container className={classes.tabContent}>
      <Grid item xs={8} className={classes.gridCell}>
        <TextField
          label='Descrição'
          name='nmGrupoEconomico'
          value={values.nmGrupoEconomico}
          onChange={e => setFieldValue('nmGrupoEconomico', e.target.value)}
          error={touched.nmGrupoEconomico && errors.nmGrupoEconomico !== undefined}
          helperText={
            touched.nmGrupoEconomico && errors.nmGrupoEconomico ? `${errors.nmGrupoEconomico}` : ''
          }
          onBlur={handleBlur}
        />
      </Grid>

        <Grid item xs={4}>
          <AutoComplete<Cliente>
            key={key}
            label={'Cliente'}
            variant='standard'
            name='cliente'
            error={touched.cliente !== undefined && errors.cliente !== undefined}
            helperText={touched.cliente && errors.cliente !== undefined ? "Campo obrigatório" : ''}
            value={values.cliente}
            genericApi={clienteAPI}
            searchField='nmFantasia'
            orderField='nmFantasia'
            staticSearchParams=',stCliente:1'
            getLabel={opt => opt?.nmFantasia || ''}
            getValue={opt => opt?.idCliente}
            onChangeValue={e => {
              if (e) {
                setFieldValue('cliente', e);
              }
            }}
          />
      </Grid>
    </Grid>
  );
}

export default EditarDadosUsuario;
