import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Dispatch } from 'redux';
import { MainStateType } from 'root-states';
import Swal from 'root-components/swal/swal';
import { useFormik } from 'formik';
import { Person, ManageAccounts } from '@mui/icons-material';
import { useParams, Params, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchAction } from 'root-states/root-dispatcher';
import GrupoEconomico from 'root-models/grupo-economico';
import LoadingSwal from 'root-components/loadingswal/loading-swal';
import { ButtonFABMenu } from '@bubotech/sumora-react-components/lib';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import VerticalTabs from '@bubotech/sumora-react-components/lib/verticaltabs';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import GrupoEconomicoAPI from 'root-resources/api/grupo-economico';

import EditarDados from './editar-dados';
import Cliente from './cliente';
import GrupoEconomicoEmpresa from 'root-models/grupo-economico-empresa';
import ClienteModel from 'root-models/cliente';

/**
 * Tipo dos valores do formik
 */
export interface EditarGrupoEconomicoFormikValuesType extends GrupoEconomico { }

export type EditarGrupoEconomicoPropType = {};

/**
 * View de edição de grupo econômico
 *
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 * @param {EditarGrupoEconomicoPropType} props
 */
function EditarGrupoEconomico(props: EditarGrupoEconomicoPropType): JSX.Element {
  const history = useNavigate();
  const api = new GrupoEconomicoAPI();

  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const isLoading = useSelector<MainStateType, boolean>(state => state.appLayoutReducer.mainLoading);
  const { id }: Readonly<Params<string>> = useParams();

  const [grupoEmpresaList, setGrupoEmpresaList] = useState<GrupoEconomicoEmpresa[]>([]);
  const [grupoEmpresaListToDelete, setGrupoEmpresaListToDelete] = useState<GrupoEconomicoEmpresa[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const [initialValues, setInitialValues] = useState<EditarGrupoEconomicoFormikValuesType>({
    nmGrupoEconomico: '',
    cliente: undefined,
    grupoEconomicoEmpresaList: []
  });

  const { values, errors, touched, handleBlur, handleSubmit, handleChange, setFieldValue } =
    useFormik<GrupoEconomico>({
      validateOnBlur: true,
      validateOnChange: false,
      enableReinitialize,
      initialValues,
      validationSchema: Yup.object().shape({
        nmGrupoEconomico: Yup.string().required('Campo obrigatório'),
        cliente: Yup.object().required('Campo obrigatório')
      }),
      onSubmit: handleSubmitFormik
    });

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar('Grupo Econômico');

    if (id) {
      setEnableReinitialize(true);
    }
  });

  useEffect(() => {
    if (!enableReinitialize) return;
    appLayoutActions.setLoading(true);

    api
      .findById(id)
      .then(res => {
        setInitialValues(res.data);
        setEnableReinitialize(false);
        setGrupoEmpresaList(res.data.grupoEconomicoEmpresaList || []);
        appLayoutActions.setLoading(false);
      })
      .catch(() => {
        appLayoutActions.setLoading(false);

        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: 'Ocorreu um erro',
          text: 'Falha ao carregar os dados',
          icon: 'error'
        });
      });

    // eslint-disable-next-line
  }, [enableReinitialize]);

  return (
    <main style={{ height: '100%' }}>
      <VerticalTabs
        title={id ? 'Edição de Grupo de Econômico' : 'Cadastro de Grupo de Econômico'}
        menuEffect
        selectedIndex={selectedIndex}
        onChangeIndex={tab => setSelectedIndex(tab)}
        tabs={[
          {
            content: (
              <EditarDados
                onChange={handleChange}
                setFieldValue={setFieldValue}
                values={values}
                touched={touched}
                errors={errors}
                handleBlur={handleBlur}
              />
            ),
            label: 'Dados do Grupo',
            icon: <Person />
          },
          {
            disabled: !values.cliente,
            content: (
              <Cliente
                empresaSelectedList={grupoEmpresaList}
                addCliente={addEmpresaInGrupo}
                deleteCliente={deletarEmpresaInGrupo}
                values={values}
              />
            ),
            label: 'Cliente',
            icon: <ManageAccounts />
          }
        ]}
      />

      <ButtonFABMenu
        disabled={isLoading}
        primaryAction={{
          onClick: (e: any) => handleSubmit(e),
          iconProps: { color: 'inherit' }
        }}
        secondaryAction={{
          onClick: () => history('/cadastros/grupo-economico'),
          iconProps: { color: 'inherit' }
        }}
      />
    </main>
  );

  /**
   * Adicionar grupo na lista
   * @param values
   */
  function deletarEmpresaInGrupo(values: GrupoEconomicoEmpresa) {
    const grupoEmpresaListAux = [...grupoEmpresaList];
    const itemToDelete = grupoEmpresaListAux.find(
      toDelete => toDelete.idGrupoEconomicoEmpresa === values?.idGrupoEconomicoEmpresa
    );
    if (itemToDelete) {
      const position = grupoEmpresaListAux.indexOf(itemToDelete);
      grupoEmpresaListAux.splice(position, 1);
      setGrupoEmpresaList(grupoEmpresaListAux);

      if (itemToDelete.stRegistro === 1) {
        itemToDelete.stRegistro = 2;
        const grupoEmpresaListToDeleteAux = [...grupoEmpresaListToDelete];
        grupoEmpresaListToDeleteAux.push(itemToDelete);
        setGrupoEmpresaListToDelete(grupoEmpresaListToDeleteAux);
      }
    }
  }

  /**
   * Adicionar grupo na lista
   * @param values
   */
  function addEmpresaInGrupo(values: ClienteModel) {
    const grupoEmpresaListAux = [...grupoEmpresaList];

    grupoEmpresaListAux.push({
      cliente: values,
      stRegistro: 0,
      idGrupoEconomicoEmpresa: Math.random().toString()
    });

    setGrupoEmpresaList(grupoEmpresaListAux);
  }

  /**
   * Manipula o evento de submit do Formik
   *
   * @param {EditarGrupoEconomicoFormikValuesType} values - Valores do submit
   * @param {FormikHelpers<EditarGrupoEconomicoFormikValuesType>} formikHelpers - Auxiliares
   */
  async function handleSubmitFormik(
    values: EditarGrupoEconomicoFormikValuesType
  ) {  
    let error = false;
    const grupoEmpresaListAux = grupoEmpresaList;

    const contemEmpresa = grupoEmpresaListAux.filter((item) => item.cliente?.idCliente === values.cliente?.idCliente)

    if (contemEmpresa.length > 0) {
      Swal({
        showConfirmButton: true,
        title: 'Atenção',
        text: 'A empresa do grupo econômico não pode estar na lista de empresas',
        icon: 'warning'
      });

      return;
    }

    LoadingSwal({ text: 'Carregando' });

    grupoEmpresaListAux.forEach(grupoEmpresa => {
      if (grupoEmpresa.stRegistro === 0) {
        delete grupoEmpresa.idGrupoEconomicoEmpresa;
      }
    });

    values.grupoEconomicoEmpresaList = grupoEmpresaList || [];
    grupoEmpresaListToDelete.forEach(grupoEmpresa => {
      values.grupoEconomicoEmpresaList.push(grupoEmpresa);
    });

    if (!id) {
      values.idGrupoEconomico = null;
      await api.save(values).catch(e => {
        error = true;
      });
    } else {
      values.idGrupoEconomico = id;
      await api.update(values).catch(e => {
        error = true;
      });
    }

    if (!error) {
      Swal({
        showConfirmButton: true,
        title: 'Sucesso',
        text: id ? 'Editado com sucesso' : 'Cadastro com sucesso',
        icon: 'success'
      });

      history('/cadastros/grupo-economico');
    } else {
      Swal({
        showConfirmButton: true,
        title: 'Erro',
        text: id ? 'Erro ao editar' : 'Erro ao cadastrar',
        icon: 'error'
      });
    }
  }
}

export default EditarGrupoEconomico;
