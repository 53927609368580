/**
 * Enum de keys das telas do sistema para diferenciar o componente CardFilters de cada uma
 *
 * @author Marcos Davi
 */
enum ScreensKeysEnum {
  CLIENTE = 'CLIENTE',
  GRUPO_ECONOMICO = 'GRUPO_ECONOMICO'
}

export default ScreensKeysEnum;
