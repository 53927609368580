
import Dashboard from 'root-views/dashboard/dashboard';
import Clientes from './cliente/cliente';
import EditarCliente from './cliente/editar-cliente';
import GrupoEconomicos from './grupo-economico/grupo-economico';
import EditarGrupoEconomico from './grupo-economico/editar-grupo-economico';

export type ScreenRoutesType = {
  path: string;
  exactPath?: boolean;
  component: any;
};

/**
 * Constante com as definições das rotas das Screens/Views/Telas da aplicação
 */
const viewsRoutes: ScreenRoutesType[] = [
  {
    path: '/',
    exactPath: true,
    component: Dashboard
  },
  {
    path: '/cliente',
    exactPath: true,
    component: Clientes
  },
  {
    path: '/cliente/editar/:id',
    component: EditarCliente
  },
  {
    path: '/cliente/novo',
    component: EditarCliente
  },
  {
    path: '/grupo-economico',
    exactPath: true,
    component: GrupoEconomicos
  },
  {
    path: '/grupo-economico/editar/:id',
    component: EditarGrupoEconomico
  },
  {
    path: '/grupo-economico/novo',
    component: EditarGrupoEconomico
  }
];

export default viewsRoutes;
