import React, { useEffect, useCallback } from 'react';
import { BrowserRouter, Route, useNavigate, Routes } from 'react-router-dom';
import AppMainLayout from 'root-views';
import Login from 'root-views/login/login';
import RecuperarSenha from 'root-views/recuperarsenha/recuperar-senha';

/**
 * Cria e declara as rotas principais do App
 *
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 */
function createRoutes(): JSX.Element {
  function Redirect({ to }: any) {
    let navigate = useNavigate();
    const navigateAux = useCallback(navigate, [navigate]);
    useEffect(() => {
      navigateAux(to);
    }, [navigateAux, to]);
    return null;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Redirect to='/dashboard' />} />
        <Route path='/login' element={<Login />} />
        <Route path='/resetar-senha' element={<RecuperarSenha />} />
        <Route path='/dashboard' element={<AppMainLayout />} />
        <Route path='/cadastros/*' element={<AppMainLayout />} />
      </Routes>
    </BrowserRouter>
  );
}

export default createRoutes;
