/**
 * Responsável pela memória das URL's das API's do sistema
 *
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 * @abstract
 */
abstract class URL {
  /**
   * URL da API de autenticação
   *
   * @static
   */
  public static readonly OAUTH = process.env.REACT_APP_OAUTH_URL;

  /**
   * URL da API principal
   *
   * @static
   */
  public static readonly API = process.env.REACT_APP_API_URL;
}

export default URL;
